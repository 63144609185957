import MainBlock from "@container/MainBlock";
import WrapperBlock from "@container/WrapperBlock";
import { AdsImu2 } from "@elements/Advertisement/variant/AdsImu";
import GEArticles from "@elements/GeneralElection/GEArticles";
import GEMapWidget from "@elements/GeneralElection/GEMapWidget";
import { type ProcessedArticleData } from "@transformer/useOsResponse";
import { PageAdTargetValue } from "@typings/Ads.d";
import { ReactElement } from "react";

type GeVariant1Props = {
  geGovTechWidget: string;
  keywordArticles: ProcessedArticleData[];
  tagAlias: string;
  sectionName: string;
  pageAdTargetValue: PageAdTargetValue;
  showBreadcrumbs: boolean;
  showTitle: boolean;
  tagName: string;
};

export default function GEVariant1({
  geGovTechWidget,
  keywordArticles,
  tagAlias,
  sectionName,
  pageAdTargetValue,
  showBreadcrumbs,
  showTitle,
  tagName,
}: GeVariant1Props): ReactElement {
  return (
    <>
      <WrapperBlock className="flex flex-col lg:flex-row justify-between">
        <MainBlock
          sectionName={tagName}
          showBreadcrumbs={showBreadcrumbs}
          showTitle={showTitle}
          className="lg:!pr-0 lg:border-0 ge-variant-1"
        >
          {geGovTechWidget && (
            <GEMapWidget geGovTechContent={geGovTechWidget} />
          )}
          <GEArticles
            variant="main"
            keywordArticles={keywordArticles}
            tagAlias={tagAlias}
            sectionName={sectionName}
            pageAdTargetValue={pageAdTargetValue}
          />
        </MainBlock>
        <div className="lg:w-4/12 lg:pr-xxs lg:pl-md">
          <div
            data-testid="side-block"
            className="lg:sticky lg:top-[75px] lg:bottom-1/4 lg:!h-auto"
          >
            <AdsImu2
              sectionName={sectionName}
              pageAdTargetType={pageAdTargetValue}
            />
          </div>
        </div>
      </WrapperBlock>
    </>
  );
}
