/**
 * Company: SPHMedia
 * Description: [GE widet with map]
 */

import Divider from "@elements/Divider/Divider";
import { ReactElement } from "react";
import { twMerge } from "tailwind-merge";

/**
 * GEMapWidget
 * Description: [GE widget by data.gov.sg (https://dgs-elections.vercel.app/en/embed)]
 * @param props - The props required by the component (if any)
 * @returns The rendered React element
 */
export default function GEMapWidget({
  geGovTechContent,
  wrapperStyle = "",
}: {
  geGovTechContent: string;
  showTitle?: boolean;
  wrapperStyle?: string;
}): ReactElement {
  // const widgetTitle = "தொகுதி எல்லை வரைபடம்";

  return (
    <>
      <div
        className={twMerge(
          "flex flex-col gap-xs mb-5 bg-grey-950",
          wrapperStyle,
        )}
      >
        <div
          dangerouslySetInnerHTML={{ __html: geGovTechContent }}
          className="px-2.5 lg:px-0"
        />
      </div>
      <Divider className="border-b border-gray-2200 my-5" />
    </>
  );
}
