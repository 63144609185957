import { pubPageAdTargeting } from "@elements/Advertisement/AdsConstants";
import CatfishAd from "@elements/Advertisement/CatfishAd";
import { GoogleAdsSlotFactory } from "@elements/Advertisement/helpers";
import PrestitialAd from "@elements/Advertisement/PrestitialAd";
import { AdsLb1 } from "@elements/Advertisement/variant/AdsLb";
import FestivalWrapper from "@elements/FestivalBanner/FestivalWrapper";
import GAData, { GADataProps } from "@elements/GAData/GAData";
import GEWebBanner from "@elements/GeneralElection/GEWebBanner";
import MetaTags, { MetaTagsProps } from "@elements/MetaTags/MetaTags";
import { Footer } from "@footer/Footer";
import { Header } from "@header/Header";
import { HEADER_MAIN_MENU } from "@header/HeaderMenuConstants";
import { HeaderMenuList } from "@header/HeaderMenuList";
import { ManavarMurasuHeader } from "@header/ManavarMurasu/ManavarMurasuHeader";
import getPageDetails from "@helper/getPageDetails";
import { useNeuronPageViewPush } from "@hooks/useAnalyticsCall/useNeuronPageViewPush";
import useOKTAUserInfo from "@hooks/useOktaUserInfo";
import useScript from "@hooks/useScript";
import useSendPermutiveIdentify from "@hooks/useSendPermutiveIdentify";
import { AdTargetingType, PageAdTargetValue } from "@typings/Ads";
import { ReactElement, ReactNode } from "react";

export type AdsSettings = {
  isPrestitialEnabled: boolean | undefined;
  isCatfishEnabled: boolean | undefined;
  isOutbrainEnabled: boolean | undefined;
};

export type OutbrainMainProps = Readonly<{
  testId?: string;
  children: ReactNode;
  gaData?: GADataProps;
  metaTagsProps?: MetaTagsProps;
  sectionName?: string;
  hasLb1Ads?: boolean;
  mobwebview?: boolean;
  pageAdTargetValue: PageAdTargetValue;
  suppliedAdsSettings: AdsSettings;
  gsChannels?: string[];
  geHomeWidget?: boolean;
}>;

/*
 * Container to provide a fixed width and horizontal padding around the content. Typically paired
 * with a Layout component to create reusable templates across all pages. Width of child content as
 * follows with each screen size:

  Viewport width >= 1280: Children component width: 1150px
  Viewport width >= 1024: Children component width: 990px
  Viewport width >= 992: Children component width: 960px
  Viewport width >= 768: Children component width: 720px
  Viewport width >= 576: Children component width: 540px
  All other viewport widths: Children component width: 100% - padding 30px

 * @param {ReactElement} children - Any React Element but likely used to wrap a Tamil Murasu module
 * @param {String} testId - Unique string used to target elements when testing
 * @returns {ReactElement} - A wrapped module with a fixed width that resizes on view port resize
 */

export default function OutbrainMainContainer({
  children,
  testId = "main-container-outbrain",
  gaData,
  metaTagsProps,
  sectionName,
  hasLb1Ads = true,
  mobwebview = false,
  pageAdTargetValue,
  suppliedAdsSettings,
  gsChannels,
  geHomeWidget = false,
}: OutbrainMainProps): ReactElement {
  const { hasDesktopNav } = getPageDetails(sectionName) || {
    hasDesktopNav: true,
  };

  useOKTAUserInfo();
  useScript({
    id: "mysph-lightbox-lib",
    src: import.meta.env.VITE_SPH_LIGHTBOX as string,
  });
  useNeuronPageViewPush();
  useSendPermutiveIdentify();

  if (sectionName === "maanavar-murasu") {
    hasLb1Ads = false;
  }
  sectionName = sectionName == "/" ? "home" : sectionName;
  const pageAdTarget = pubPageAdTargeting[pageAdTargetValue] as AdTargetingType<
    string,
    PageAdTargetValue
  >;

  return (
    <>
      <FestivalWrapper className="festival">
        {!mobwebview && <Header sectionName={sectionName} />}
        {sectionName === "maanavar-murasu" && <ManavarMurasuHeader />}
        {hasDesktopNav && (
          <div className="hidden lg:block sticky top-0 z-50 bg-white-100">
            <HeaderMenuList
              testId="desktop-main-links"
              items={HEADER_MAIN_MENU}
              type="main"
              isHamburger={false}
            />
          </div>
        )}

        {sectionName != "general-election-2025" && !geHomeWidget && (
          <GEWebBanner />
        )}
        {hasLb1Ads && !mobwebview && (
          <AdsLb1
            sectionName={sectionName}
            pageAdTargetType={pageAdTargetValue}
            gsChannels={gsChannels}
          />
        )}

        <div
          data-testid={testId}
          className="xl:max-w-xl w-full mx-auto xxs:px-sm lg:p-0 max-xsm:px-1"
        >
          <PrestitialAd
            slot={GoogleAdsSlotFactory.prestitial(sectionName)}
            isPrestitialEnabled={suppliedAdsSettings.isPrestitialEnabled}
            pubAdTargeting={[pageAdTarget]}
            gsChannels={gsChannels}
          />
          {gaData && <GAData {...gaData} />}
          {metaTagsProps && <MetaTags {...metaTagsProps} />}
          {children}
        </div>
        {!mobwebview && <Footer />}
        <CatfishAd
          slot={GoogleAdsSlotFactory.catfish(sectionName)}
          isCatfishEnabled={suppliedAdsSettings.isCatfishEnabled}
          pubAdTargeting={[pageAdTarget]}
          gsChannels={gsChannels}
        />
      </FestivalWrapper>
    </>
  );
}
