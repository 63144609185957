/**
 * Company: SPHMedia
 * Description: [GE Live blog]
 */
import { ReactElement } from "react";
import { twMerge } from "tailwind-merge";

type GELiveBlogStyleProps = {
  wrapperStyle?: string;
};

type GELiveBlogProps = {
  variant: "sidebar" | "body";
  geLiveBlogContent: string;
  styles?: GELiveBlogStyleProps;
};

/**
 * GELiveBlog
 * Description: [GE 24LiveBlog widget]
 * @param props - The props required by the component (if any)
 * @returns The rendered React element
 */
export default function GELiveBlog({
  variant,
  geLiveBlogContent,
  styles,
}: GELiveBlogProps): ReactElement {
  const wrapperStyle = styles?.wrapperStyle ? styles.wrapperStyle : "w-auto";

  return (
    <div
      className={twMerge(
        `${variant == "sidebar" ? wrapperStyle : ""} min-h-[500px]`,
        "mb-380 lg:mb-0",
      )}
    >
      <div
        className={twMerge(
          "h-full px-2.5 lg:px-0",
          variant == "sidebar" ? "px-0 lg:px-0" : "",
        )}
        dangerouslySetInnerHTML={{ __html: geLiveBlogContent }}
      />
    </div>
  );
}
