/**
 * Company: SPHMedia
 * Description: [GE ST widet]
 */
import { ReactElement } from "react";

/**
 * GESTWidget
 * Description: [GE ST widget (https://uat.straitstimes.com/multimedia/graphics/proj-2402-ge-results/tm-widget.html)]
 * @param props - The props required by the component (if any)
 * @returns The rendered React element
 */
export default function GESTWidget({
  geSTWidgetContent,
}: {
  geSTWidgetContent: string;
}): ReactElement {
  return (
    <div className="border-2 border-pink-701 my-5 bg-white-100 mb-380">
      <div
        className="pt-5"
        dangerouslySetInnerHTML={{ __html: geSTWidgetContent }}
      />
    </div>
  );
}
