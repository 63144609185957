import ChevronImage from "@assets/Chevron_dark.svg";
import GELogo from "@assets/ge/GE2025-logo.png";
import ArticleCard from "@components/Card/ArticleCard";
import Image from "@components/ResponsiveImage/Image";
import Divider from "@elements/Divider/Divider";
import GELiveBlog from "@elements/GeneralElection/GELiveBlog";
import GESTWidget from "@elements/GeneralElection/GESTWidget";
import Heading from "@elements/Typography/Heading";
import { isArrayWithElements } from "@helper/utils";
import { GEWidgetProps } from "@typings/PagePayloadType";
import { ReactElement } from "react";
import { Link } from "react-router-dom";
import { twMerge } from "tailwind-merge";

type GELiveBlogStyleProps = {
  wrapperStyle?: string;
};

export default function GEHomeWidget({
  geSTWidget,
  geRecordedVideo,
  geTopStories,
  // geGovTechWidget,
  geLiveBlog,
}: GEWidgetProps): ReactElement {
  const title = "பொதுத் தேர்தல் 2025";

  const styles: GELiveBlogStyleProps = {
    wrapperStyle: geRecordedVideo
      ? "max-h-[600px] w-full px-0 "
      : "xl:w-[600px]",
  };

  const geLiveBlogStyle = geRecordedVideo
    ? "w-full lg:w-1/3 xl:w-1/4 lg:pr-5 order-second lg:order-first"
    : "w-full lg:w-1/2 xl:w-1/2 lg:pr-5 lg:order-first";
  const geArticleStyle = geRecordedVideo
    ? "w-full lg:w-full xl:w-1/4 order-last"
    : "w-full lg:w-1/2 xl:w-1/2 order-last";

  return (
    <div className="flex flex-col gap-xs bg-gray-500 mb-5 xl:mx-[-95px] xl:px-0 xl:w-[100% + 190px]">
      <div className="w-full h-2.5 bg-gradient-to-r from-pink-701 to-pink-301 xl:mb-5"></div>
      <div className="xl:max-w-xl w-full mx-auto xxs:px-sm xl:p-0 max-xsm:px-1 mb-5">
        <div className="">
          <Image
            srcWidth={48}
            srcHeight={48}
            src={GELogo}
            objectFit="contain"
            alt={title}
            className="w-12 absolute mt-5 md:mt-0"
          />
          <div className="mb-5 md:mb-2">
            <Heading
              Element="h1"
              className={twMerge(
                "inline font-bold font-primary text-2.5md xl:text-1xl leading-960 text-pink-200 mb-5 pl-12 ml-2.5",
              )}
            >
              {title}
            </Heading>
            <div className="ml-2.5 pl-12 md:inline md:float-right md:mt-4">
              <Link
                reloadDocument
                to={`/general-election-2025`}
                target="_blank"
              >
                மேலும் படிக்க
                <img
                  alt="toggle-button-chevie"
                  height={24}
                  width={24}
                  className="pl-[5px] inline"
                  src={ChevronImage}
                />
              </Link>
            </div>
          </div>
          <Divider className="border-b border-gray-2200 mb-5" />
        </div>
        {geSTWidget && <GESTWidget geSTWidgetContent={geSTWidget} />}

        <div className="flex flex-wrap">
          {geLiveBlog && (
            <div className={geLiveBlogStyle}>
              <GELiveBlog
                variant="sidebar"
                geLiveBlogContent={geLiveBlog}
                styles={styles}
              />
            </div>
          )}
          {geRecordedVideo && (
            <div className="w-full xl:w-1/2 lg:w-2/3 xl:pr-5 ge-youtube-video-home mb-380 xl:mb-0 order-first lg:order-second">
              <div dangerouslySetInnerHTML={{ __html: geRecordedVideo }} />
            </div>
          )}
          <div className={geArticleStyle}>
            {isArrayWithElements(geTopStories) ? (
              <>
                <div className="text-sm font-medium font-secondary leading-6 pb-2.5">
                  GE2025 செய்திகள்
                </div>
                {geTopStories.map((article, i, row) => (
                  <ArticleCard
                    displayWidth={135}
                    displayHeight={90}
                    key={article.id}
                    elements={{ hasParagraph: false, hasDate: true }}
                    overrideStyle={{
                      cardWrapper: twMerge(
                        "flex-row",
                        i + 1 === row.length
                          ? "border-b-0"
                          : "border-b-gray-2050",
                        i === 0 && "pt-0",
                      ),
                      titleStyle:
                        "font-semibold font-primary text-2sm text-gray-100 hover:text-gray-100",
                      imageWrapperStyle: "hidden",
                      contentWrapper: "gap-xs",
                      titleWrapper: "gap-0",
                      imageStyle: "w-full h-23",
                    }}
                    refMedium="home_ge_top_stories"
                    {...article}
                  />
                ))}
              </>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}
