/* eslint-disable react-hooks/rules-of-hooks */

import MainContainer from "@container/MainContainer";
import GEVariant1 from "@elements/GeneralElection/GEVariant1";
import GEVariant2 from "@elements/GeneralElection/GEVariant2";
import GEVariant3 from "@elements/GeneralElection/GEVariant3";
import GEVariant4 from "@elements/GeneralElection/GEVariant4";
import { APP_VIEW_COOKIE } from "@header/HeaderMenuConstants";
import { SpecialHeader } from "@header/SpecialHeader";
import { GEPayloadResponseType } from "@pages/CustomPages/Ge.server";
import { GeMetaData } from "@pages/CustomPages/GeMetaData";
import ErrorPage from "@pages/ErrorPage";
import NotFound from "@pages/NotFound";
import type { TRouteWithoutRedirect } from "@sphtech/web2-core/ssr";
import {
  CustomContext,
  ResponseType,
  useRouteContext,
} from "@sphtech/web2-core/ssr";
import { PageAdTargetingTypeEnum } from "@typings/Ads.d";
import Cookies from "js-cookie";
import { useSearchParams } from "react-router-dom";

export default function GePage() {
  const routeContext: CustomContext<
    TRouteWithoutRedirect<GEPayloadResponseType, string>
  > = useRouteContext();
  const dataLoaderResponse = routeContext.context;
  if (dataLoaderResponse.type === ResponseType.CLIENT_ERROR) {
    return <NotFound />;
  } else if (dataLoaderResponse.type === ResponseType.SERVER_ERROR) {
    return <ErrorPage message="Something went wrong" />;
  }

  const [searchParams] = useSearchParams();
  const mobwebview = searchParams.get("mobwebview");
  if (mobwebview && mobwebview == "true") {
    Cookies.set(APP_VIEW_COOKIE, "true", {
      path: "/",
      SameSite: "None",
      Secure: true,
    });
  } else {
    Cookies.set(APP_VIEW_COOKIE, "false", {
      path: "/",
      expires: new Date(0), // Thu, 01 Jan 1970 00:00:00 UTC
    });
  }
  const mobwebviewCookie = Cookies.get(APP_VIEW_COOKIE);
  const mobwebviewBool = mobwebviewCookie == "true";

  const {
    tagAlias,
    geRecordedVideo,
    geSTWidget,
    geGovTechWidget,
    geLiveBlog,
    geVariant,
  } = dataLoaderResponse.payload;
  const keywordArticles = dataLoaderResponse.payload.data;

  const tagName = "பொதுத் தேர்தல் 2025";
  const showBreadcrumbs = false;
  const showTitle = false;
  const pageAdTargetValue = PageAdTargetingTypeEnum.TAG;
  const sectionName = "general-election-2025";

  const { gaData, metaTags } = GeMetaData({
    tagName,
    tagAlias,
  });

  return (
    <MainContainer
      gaData={gaData}
      metaTagsProps={metaTags}
      sectionName={sectionName}
      mobwebview={mobwebviewBool}
      pageAdTargetValue={pageAdTargetValue}
    >
      <SpecialHeader title={tagName} />

      {(() => {
        switch (geVariant) {
          case "boundry_change":
            return (
              <GEVariant1
                geGovTechWidget={geGovTechWidget}
                keywordArticles={keywordArticles}
                tagAlias={tagAlias}
                sectionName={sectionName}
                pageAdTargetValue={pageAdTargetValue}
                showBreadcrumbs={showBreadcrumbs}
                showTitle={showTitle}
                tagName={tagName}
              />
            );
          case "parliment_desolve":
            return (
              <GEVariant2
                geGovTechWidget={geGovTechWidget}
                geLiveBlog={geLiveBlog}
                keywordArticles={keywordArticles}
                tagAlias={tagAlias}
                sectionName={sectionName}
                pageAdTargetValue={pageAdTargetValue}
                showBreadcrumbs={showBreadcrumbs}
                showTitle={showTitle}
                tagName={tagName}
              />
            );
          case "nomination":
            return (
              <GEVariant3
                geGovTechWidget={geGovTechWidget}
                geLiveBlog={geLiveBlog}
                geRecordedVideo={geRecordedVideo}
                keywordArticles={keywordArticles}
                tagAlias={tagAlias}
                sectionName={sectionName}
                pageAdTargetValue={pageAdTargetValue}
                showBreadcrumbs={showBreadcrumbs}
                showTitle={showTitle}
                tagName={tagName}
              />
            );
          case "polling_results":
            return (
              <GEVariant4
                geGovTechWidget={geGovTechWidget}
                geLiveBlog={geLiveBlog}
                geRecordedVideo={geRecordedVideo}
                geSTWidget={geSTWidget}
                keywordArticles={keywordArticles}
                tagAlias={tagAlias}
                sectionName={sectionName}
                pageAdTargetValue={pageAdTargetValue}
                showBreadcrumbs={showBreadcrumbs}
                showTitle={showTitle}
                tagName={tagName}
              />
            );
          default:
            return null;
        }
      })()}
    </MainContainer>
  );
}
